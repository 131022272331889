.assessment-progress {
  width: 100px;
  height: 25px;
  border: 1px solid #dfdfdf;
  position: relative;
  text-align: center;
  display: inline-block;
  top: 5%;
}

.assessment-progress > span {
  display: block;
  height: 100%;
  position: relative;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
}

.assessment-block {
  height: 35px;
}

.assessment-percent {
  height: 100%;
  margin-left: 2px;
  margin-top: 5px;
}
