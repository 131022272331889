body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: 'Rubik', sans-serif;
}

.login-form {
  margin: 25px 0 25px 0;
}

.cl-def-1 {
  color: #2e3192 !important;
}

.cl-def-2 {
  color: #1f1b62 !important;
}

.h-100 {
  min-height: calc(100vh - 55px);
}

.spin {
  animation: fa-spin 2s infinite linear;
  color: #007bff;
}

/*This is modifying the btn-primary colors but you could create your own .btn-something class as well*/
.cl-btn-def-1 {
  color: #2e3192 !important;
  background-color: #fff !important;
  border-color: #2e3192 !important; /*set the color you want here*/
}

.cl-btn-def-1:hover, .cl-btn-def-1:focus, .cl-btn-def-1:active, .cl-btn-def-1.active, .open > .dropdown-toggle.cl-btn-def-1 {
  color: #fff !important;
  background-color: #2e3192 !important;
  border-color: #2e3192 !important; /*set the color you want here*/
}