.error-position {
  right: 15px;
  top: 15%
}

.error-text {
  padding: 5px 10px;
}

.error-close-button {
  right: 5px;
  top: 5px;
}

@media only screen and (max-width: 570px) {
  .error-position {
    right: 5px;
    top: 15%
  }
}