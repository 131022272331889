body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: 'Rubik', sans-serif;
}

.login-form {
  margin: 25px 0 25px 0;
}

.cl-def-1 {
  color: #2e3192 !important;
}

.cl-def-2 {
  color: #1f1b62 !important;
}

.h-100 {
  min-height: calc(100vh - 55px);
}

.spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
  color: #007bff;
}

/*This is modifying the btn-primary colors but you could create your own .btn-something class as well*/
.cl-btn-def-1 {
  color: #2e3192 !important;
  background-color: #fff !important;
  border-color: #2e3192 !important; /*set the color you want here*/
}

.cl-btn-def-1:hover, .cl-btn-def-1:focus, .cl-btn-def-1:active, .cl-btn-def-1.active, .open > .dropdown-toggle.cl-btn-def-1 {
  color: #fff !important;
  background-color: #2e3192 !important;
  border-color: #2e3192 !important; /*set the color you want here*/
}
.custom-nav {
  margin: 0 25px 0 25px;
}

@media only screen and (max-width: 570px) {
  .custom-nav {
    margin: 0 0 0 0;
  }
}
.error-position {
  right: 15px;
  top: 15%
}

.error-text {
  padding: 5px 10px;
}

.error-close-button {
  right: 5px;
  top: 5px;
}

@media only screen and (max-width: 570px) {
  .error-position {
    right: 5px;
    top: 15%
  }
}
.info-panel {
  margin: 15px 0 15px 0;
}

.info-panel-text {
  margin: 5px 0 5px 0;
  padding-left: 5px;
}
.form-group-border {
  padding: 15px 15px;
  border: #dfdfdf 1px solid;
  border-radius: 5px;
}
.row-btn {
  margin: 0 10px 0 10px;
}

.row-btn-group {
  max-height: 20px;
}

.row-caret {
  padding: 0 25px 0 5px;
}
.team-component {
  border: #dfdfdf solid 1px;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 15px 0 15px 0;
}


.report-btn-panel {
  margin: 5px 0 5px 0;
  padding: 5px 0 5px 0;
  border-bottom: #dfdfdf solid 1px;
  border-top: #dfdfdf solid 1px;
}
.rep-result {
  margin-top: 25px;
}

.col-max-250 {
  max-width: 250px;
  word-wrap: break-word;
}

.col-min-150 {
  min-width: 150px;
  word-wrap: break-word;
}

.comp-charts {
  margin: 15px 0 15px 0;
}

.comp-chart-header {
  background-color: #dfdfdf;
  color: #2e3192;

  min-height: 35px;

  padding: 5px 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.col-p0 {
  background: #FDEDEC;
}
.main-panel {

  margin-top: 15px;
  margin-bottom: 15px;

  padding: 15px 15px;
  border: #dfdfdf solid 1px;
  border-radius: 5px;
}

.pagination-panel {
  padding-bottom: 5px;
  padding-top: 5px;
}

.pagination-group {
  border-color: #2e3192 !important;
  color: #2e3192 !important;
}

.loader {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}

.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #2e3192;
  -webkit-animation: loading 2s linear infinite;
          animation: loading 2s linear infinite;
}

@-webkit-keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

.filter-panel {
  padding: 5px 0 5px 0;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
}

.filter-element {
  padding: 1px 0 1px 0;
}

.filter-drop {
  display: inline-block;
}
.content-recs {
  border-radius: 2px;
  border: #dfdfdf solid 1px;
  padding: 5px 0 5px 0;
  margin: 10px 0 10px 0;
}

.recs-header{
  margin: 15px 0 5px 0;
}

.rec-img {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.rec-type {
  font-size: 1rem;
  margin-top: 15px;
  color: #2e3192;
}

.rec-name {
  font-size: 1.1rem;
  margin-top: 5px;
  color: orangered;
}

.rec-author {
  font-size: 1rem;
  margin-top: 5px;
  color: #2e3192;
}

.comp-charts {
  margin: 15px 0 15px 0;
}

.comp-chart-header {
  background-color: #dfdfdf;
  color: #2e3192;

  min-height: 35px;

  padding: 5px 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.assessment-progress {
  width: 100px;
  height: 25px;
  border: 1px solid #dfdfdf;
  position: relative;
  text-align: center;
  display: inline-block;
  top: 5%;
}

.assessment-progress > span {
  display: block;
  height: 100%;
  position: relative;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
}

.assessment-block {
  height: 35px;
}

.assessment-percent {
  height: 100%;
  margin-left: 2px;
  margin-top: 5px;
}


.col-adaptation-p0 {
  background: #FDEDEC;
}
