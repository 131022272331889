.comp-charts {
  margin: 15px 0 15px 0;
}

.comp-chart-header {
  background-color: #dfdfdf;
  color: #2e3192;

  min-height: 35px;

  padding: 5px 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}