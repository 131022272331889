.rep-result {
  margin-top: 25px;
}

.col-max-250 {
  max-width: 250px;
  word-wrap: break-word;
}

.col-min-150 {
  min-width: 150px;
  word-wrap: break-word;
}

.comp-charts {
  margin: 15px 0 15px 0;
}

.comp-chart-header {
  background-color: #dfdfdf;
  color: #2e3192;

  min-height: 35px;

  padding: 5px 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.col-p0 {
  background: #FDEDEC;
}