.main-panel {

  margin-top: 15px;
  margin-bottom: 15px;

  padding: 15px 15px;
  border: #dfdfdf solid 1px;
  border-radius: 5px;
}

.pagination-panel {
  padding-bottom: 5px;
  padding-top: 5px;
}

.pagination-group {
  border-color: #2e3192 !important;
  color: #2e3192 !important;
}

.loader {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}

.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #2e3192;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

.filter-panel {
  padding: 5px 0 5px 0;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
}

.filter-element {
  padding: 1px 0 1px 0;
}

.filter-drop {
  display: inline-block;
}