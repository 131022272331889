.team-component {
  border: #dfdfdf solid 1px;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 15px 0 15px 0;
}


.report-btn-panel {
  margin: 5px 0 5px 0;
  padding: 5px 0 5px 0;
  border-bottom: #dfdfdf solid 1px;
  border-top: #dfdfdf solid 1px;
}