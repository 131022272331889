.content-recs {
  border-radius: 2px;
  border: #dfdfdf solid 1px;
  padding: 5px 0 5px 0;
  margin: 10px 0 10px 0;
}

.recs-header{
  margin: 15px 0 5px 0;
}

.rec-img {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.rec-type {
  font-size: 1rem;
  margin-top: 15px;
  color: #2e3192;
}

.rec-name {
  font-size: 1.1rem;
  margin-top: 5px;
  color: orangered;
}

.rec-author {
  font-size: 1rem;
  margin-top: 5px;
  color: #2e3192;
}
