.row-btn {
  margin: 0 10px 0 10px;
}

.row-btn-group {
  max-height: 20px;
}

.row-caret {
  padding: 0 25px 0 5px;
}